<template>
  <div>
    <div class=" white radius-15">
      <job-experience :isComplete="true"></job-experience>

      <v-form @submit.prevent="$emit('nextHandler',3)">
        <dialog-btns
          :btnTitle="$t('forms.saveContinue')"
          :closeTitle="''"
        ></dialog-btns>
      </v-form>
    </div>
  </div>
</template>

<script>
import DialogBtns from "../../../dashboard/components/dialogs/DialogBtns.vue";
import JobExperience from "../../../dashboard/views/profile/job-experience/Index.vue";
export default {
  components: {
    JobExperience,
    DialogBtns,
  },
};
</script>

<style></style>
